<template lang="pug">
  div
    .subtitle.ma-none(v-if="isDefault") {{ 'pages.administration.default_role_desc' | translate }}
    .subtitle.ma-none(v-else-if="isSupervisor") {{ 'pages.administration.supervisor_role_desc' | translate }}
    div(v-else)
      v-scrollable(
        :bottom-offset="40"
        @scroll-complete="onScrollComplete")
        .role-owners__list
          v-input-search(
            class="role-owners__search"
            :placeholder="'pages.administration.owners_search' | translate"
            type="text"
            v-model="search"
          )
          departments-list(
            v-if="showDepartments"
            :search="search"
            :loaded="loaded"
            :items="departmentsItems"
            @add="onDepartmentAdd"
            @remove="onDepartmentRemove")

          employees-list(
            :search="search"
            :loaded="loaded"
            :items="employeesItems"
            @add="onEmployeeAdd"
            @remove="onEmployeeRemove")

</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import DepartmentsList from './departments/DepartmentsList'
import EmployeesList from './employees/EmployeesList'

export default {
  name: 'OwnersList',

  components: {
    DepartmentsList,
    EmployeesList
  },

  data: () => ({
    search: '',
    loaded: false,
    employees: {
      search: {
        items: [],
        page: 1,
        isLastPage: false
      },
      role: {
        items: [],
        page: 1,
        isLastPage: false
      }
    },
    departments: {
      search: [],
      role: []
    }
  }),

  mounted () {
    if (this.roleId !== undefined) {
      this.loadOwners()
    }
  },

  methods: {
    ...mapActions('roles', [
      'getRole',
      'getRoleOwners'
    ]),

    async loadOwners () {
      const params = { page: this.employees.role.page }
      const { data, headers } = await this.getRole({ id: this.roleId, params })
      const pagesCount = parseInt(headers['x-pagination-page-count'])
      if (data) {
        const employees = data.employees.map(el => ({ employee: el, hasRole: true }))
        const departments = data.departments.map(el => ({ department: el, hasRole: true }))
        this.employees.role.items = employees
        this.departments.role = departments
        this.employees.role.isLastPage = this.employees.role.page === pagesCount
      }
      this.loaded = true
    },

    async searchOwners () {
      const params = { q: this.search, page: this.employees.role.page }
      const { data, headers } = await this.getRoleOwners({ id: this.roleId, params })
      const pagesCount = parseInt(headers['x-pagination-page-count'])
      if (data) {
        this.employees.search.items.push(...data.employees)
        this.departments.search = data.departments
        this.employees.search.isLastPage = this.employees.role.page === pagesCount
      }
      this.loaded = true
    },

    onDepartmentAdd (item) {
      this.departments.role.push(item)
    },

    onDepartmentRemove (item) {
      const index = this.departments.role
        .findIndex(role => role.department.id === item.department.id)
      if (index !== -1) {
        this.departments.role.splice(index, 1)
      }
    },

    onEmployeeAdd (item) {
      this.employees.role.items.push(item)
    },

    onEmployeeRemove (item) {
      const index = this.employees.role.items
        .findIndex(role => role.employee.id === item.employee.id)
      if (index !== -1) {
        this.employees.role.items.splice(index, 1)
      }
    },

    onScrollComplete () {
      if (this.loaded) {
        if (this.search) {
          if (!this.employees.search.isLastPage) {
            this.employees.search.page += 1
            this.searchOwners()
          }
        } else {
          if (!this.employees.role.isLastPage) {
            this.employees.role.page += 1
            this.loadOwners()
          }
        }
      }
    },

    resetSearch () {
      this.employees.search.items = []
      this.employees.search.page = 1
      this.employees.search.isLastPage = false
    }
  },

  computed: {
    ...mapGetters('roles', [
      'currentRole'
    ]),

    employeesItems () {
      return this.search
        ? this.employees.search.items
        : this.employees.role.items
    },

    departmentsItems () {
      return this.search
        ? this.departments.search
        : this.departments.role
    },

    roleId () {
      return this.$route.params.role_id
    },

    isDefault () {
      return this.currentRole &&
        this.currentRole.isDefault
    },

    isSupervisor () {
      return this.currentRole &&
        this.currentRole.isSupervisor
    },

    showDepartments () {
      return this.currentRole &&
        !this.currentRole.isSuperAdmin
    }
  },

  watch: {
    roleId (value) {
      if (value !== undefined) {
        this.loaded = false
        if (this.search) {
          this.resetSearch()
          this.searchOwners()
        } else {
          this.employees.role.page = 1
          this.employees.role.isLastPage = false
          this.loadOwners()
        }
      }
    },

    search (value) {
      this.resetSearch()
      if (value) {
        this.loaded = false
        this.searchOwners()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .role-owners {
    &__search {
      margin-bottom: 21px;
    }
    &__list {
      padding-right: 20px;

      .form-group {
        position: relative;

        .icon-input-clear {
          right: 10px;
        }
      }
    }
  }

</style>
